<template>
  <div id="Market">
    <div class="mainTop" >
      <span>发布人:</span>
      <div><van-field
          v-model="author"
          rows="1"
          autosize
          type="textarea"
          placeholder="请输入发布人"
          minlength="5"
          maxlength="40"
        />
      </div>
    </div>

     <div >
      <van-field
        v-model="title"
        rows="1"
        autosize
        type="textarea"
        placeholder="请输入标题"
        minlength="5"
        maxlength="40"
      />
    </div>
    <div >
      <van-field
        v-model="content"
        rows="5"
        autosize
        type="textarea"
        placeholder="请输入内容"
      />
    </div>
    <div class="toSubmit" @click="toSub">提交</div>
  </div>
</template>

<script>
export default {
  name: 'Market',
  data(){
    return {
      author:'',
      title:'',
      content:'',
    }
  },
  mounted(){
    
  },
  methods:{
    toSub(){
      this.axios({
        method: 'POST',
        url: '/market/userAddMarket',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          author:this.author,
          title:this.title,
          content:this.content,
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$toast('发布成功')
          var _this = this;
          setTimeout(function(){
            _this.$router.go(-1)
          },2000)
        }
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Market{
  .toSubmit{
    height: 0.42rem;
    background: #3890FF;
    opacity: 1;
    border-radius: 5px;
    color: #F6F7FD;
    line-height: 0.42rem;
    margin: 0.2rem 0.15rem;
    text-align: center;
  }
  >div{
    background: #23252E;
  }
  .mainTop{
    margin-bottom: 0.1rem;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    height: 0.16rem;
    >span:first-child{
      color: #F6F7FD;
      margin-right: 0.06rem;
    }
    >div{
      flex: 1;
      color: #989AB1;
      font-size: 0.12rem;
    }
    .right_arrow {
      width: 0.06rem;
      height: 0.06rem;
      border-top: 1px solid #CFD0E0;
      border-right: 1px solid #CFD0E0;
      transform: rotate(45deg);
    } 
  }
}
</style>

